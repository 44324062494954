import React from 'react';
import { Link } from 'gatsby';

import { deleteAllCookies } from '../utils/cookies';

import logo from '../assets/images/fibonacci-full.svg';
import la_logo from '../assets/images/logo-dark-web.svg';

const Footer = () => (
  <footer>
    <div className="container">
      <div className="copyright">
        <Link to="/" className="logo">
          <img src={logo} alt="" height="40px" />
        </Link>
        <div className="content">
          © Copyright {new Date().getFullYear()} LawAdvisor Ventures Ltd.
        </div>
        <div className="content">
          All rights reserved.
        </div>
      </div>
      <div className="links">
        <div className="column">
          <div className="header">CONTENT</div>
          <div className="item">
            <Link to="/">Home</Link>
          </div>
          <div className="item">
            <Link to="/solutions/corporate-legal-law-and-firms/">Corporate Legal & Law firms</Link>
          </div>
          <div className="item">
            <Link to="/solutions/legal-project-management/" >Legal Project Management</Link>
          </div>
          <div className="item">
            <Link to="/features/">Features</Link>
          </div>
          <div className="item">
            <Link to="/learn/">Learn</Link>
          </div>
          <div className="item">
            <Link to="/blog/">News</Link>
          </div>
          <div className="item">
            <Link to="/steering-group/">Steering Group</Link>
          </div>
          <div className="item">
            <Link to="/implementation-partners/">Implementation Partners</Link>
          </div>
        </div>
        <div className="column">
          <div className="header">LEGAL</div>
          <div className="item">
            <Link className="item" to="/privacy-policy/">Privacy Policy</Link>
          </div>
          <div className="item">
            <Link className="item" to="/sub-processors/">Subprocessors List</Link>
          </div>
        </div>
        <div className="column">
          <div className="header">OTHER</div>
            <button style={{padding: 0, textAlign: 'left'}} className="item" onClick={() => {
              deleteAllCookies();

              location.reload();
            }}>
            Manage your cookies
          </button>
        </div>
      </div>
    </div>
    <div className="footer-divider" />
    <div className="powerd-by">
      Powered  by
      <a href="https://www.lawadvisor.ventures/" target="_blank">
        <img className="alt-logo" src={la_logo} alt='logo' height="24px" />
      </a>
    </div>
  </footer>
);

export default Footer;
